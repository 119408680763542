<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create Product
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-product-list'}"
            >
              List Product
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class="col-12 row"
              >
                <div
                  data-v-5cff0742=""
                  class="row col-6"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          id="__BVID__824__BV_label_"
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Name</label>
                        <input
                          id="h-first-name"
                          v-model="name"
                          type="text"
                          placeholder="Name"
                          class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          id="__BVID__826__BV_label_"
                          class="col-md-4 col-form-label"
                        >Description</label>
                        <input
                          v-model="description"
                          type="text"
                          placeholder="Description"
                          class="form-control col-11"
                        ><!---->
                        <!----><!----></div>
                    </div>
                  </div>
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          id="__BVID__824__BV_label_"
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Price</label>
                        <input
                          id="h-first-name"
                          v-model="price"
                          type="number"
                          placeholder="Price"
                          class="col-11 form-control"
                        ><!----><!----><!----></div>
                    </div>
                  </div>

                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__826"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col form-group">
                        <label
                          id="__BVID__826__BV_label_"
                          class="col-md-4 col-form-label"
                        >Category</label>
                        <select
                          id=""
                          v-model="category"
                          class="form-control col-11"
                          name=""
                        >
                          <option
                            v-for="(item,index) in getterListProductCategory"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-v-5cff0742=""
                  class="row col-6"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      role="group"
                      class="form-row form-group"
                    >
                      <div class="col">
                        <label
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Avatar</label>
                        <div
                          class="input-file"
                          style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose File</b>
                            <input
                              id="file"
                              type="file"
                              @change="onFileChange( $event)"
                            >
                          </label>
                        </div>

                        <div id="preview">
                          <img
                            v-if="url"
                            :src="url"
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      role="group"
                      class="form-row form-group"
                    >
                      <div class="col">
                        <div
                          class="input-file-list"
                          style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose</b>
                            <input
                              ref="file1"
                              type="file"
                              @change="onFileChangeImg1( $event)"
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="input-file-list"
                          style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose</b>
                            <input
                              ref="file2"
                              type="file"
                              @change="onFileChangeImg2( $event)"
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="input-file-list"
                          style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose</b>
                            <input
                              ref="file3"
                              type="file"
                              @change="onFileChangeImg3( $event)"
                            >
                          </label>
                        </div>
                      </div>
                      <div class="col">
                        <div
                          class="input-file-list"
                          style="cursor: pointer"
                        >
                          <label class="label-input-file">
                            <b>Choose</b>
                            <input
                              ref="file4"
                              type="file"
                              @change="onFileChangeImg4( $event)"
                            >
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div
                  data-v-5cff0742=""
                  class="offset-md-4 col"
                >
                  <button
                    data-v-5cff0742=""
                    type="button"
                    class="btn m-5 btn-primary"
                    @click.prevent="submitAddProduct()"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'

export default {

  name: 'ProductAdd',
  data() {
    return {
      name: '',
      price: 0,
      description: '',
      file: '',
      file1: '',
      file2: '',
      file3: '',
      file4: '',
      image: '',
      category: '',
      url: null,
    }
  },
  created() {
    this.getListProductCategory({})
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['getterListProductCategory']),
  },
  methods: {
    ...mapActions(['getListProductCategory', 'createProduct']),
    submitAddProduct() {
      const data = {
        name: this.name,
        image_id: this.file,
        image_1: this.file1,
        image_2: this.file2,
        image_3: this.file3,
        image_4: this.file4,
        image_ids: [],
        // eslint-disable-next-line radix
        price: parseInt(this.price),
        status: 1,
        description: this.description,
        category_ids: [this.category],
      }
      if (this.file === '') {
        // eslint-disable-next-line no-alert
        alert('choose avatar product !')
        return false
      }
      console.log('submitAddProduct', data)
      this.createProduct(data)
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg1(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file1 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg2(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file2 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg3(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file3 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg4(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file4 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
  },
}
</script>

<style scoped>
#preview {
  /*align-items: center;*/
}

#preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.input-file {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}
.input-file-list {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}

.label-input-file {
  position: relative;
  width: 100%;
}

.label-input-file b {
  display: block;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding-top: 73px;
  cursor: pointer;
}

.label-input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

</style>
